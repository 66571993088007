'use client';

import Sentry from '@/components/Sentry';
import {
  Box,
  Flex,
  VStack,
  extendTheme,
} from '@medsimples/design-system/src/chakra';
import Logo from '@public/icons/logo/logo.png';
import type { Metadata } from 'next';
import { Poppins } from 'next/font/google';
import Image from 'next/image';
import type { ReactNode } from 'react';
import { ProvidersClient } from './providers_client';

const title = 'Cadastro médico';
const description = 'Cadastro de médicos';

export const metadata: Metadata = {
  title,
  description,
  icons: ['/favicon.png'],
};

const poppins = Poppins({
  weight: ['400', '500', '600'],
  subsets: ['latin'],
});

export default function RootLayoutClient({
  children,
  theme,
  idToken,
}: {
  children: ReactNode;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  theme: any;
  idToken: string;
}) {
  const extendedTheme = extendTheme({ theme });
  return (
    <html lang='pt-BR' suppressHydrationWarning>
      <body>
        <ProvidersClient theme={extendedTheme}>
          <main className={poppins.className}>
            <Flex
              h='100vh'
              flexDirection='column'
              justifyContent='start'
              alignItems='center'
            >
              <VStack maxW='400px' paddingY={9} paddingX={7} spacing={10}>
                <Box>
                  <Image data-testid='logo' src={Logo} alt='logo' />
                </Box>
                {children}
              </VStack>
            </Flex>
            <Sentry idToken={idToken} />
          </main>
        </ProvidersClient>
      </body>
    </html>
  );
}
